<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <!-- <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            /> -->

            <!-- tree -->
            <v-img height="226" width="300" class="auth-tree" src="@/assets/images/misc/tree-4.png"></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-light.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">Welcome to SVP! 👋🏻</p>
                  <p class="mb-2">Please sign-in to your account</p>
                </v-card-text>

                <v-card-text>
                  <v-fade-transition>
                    <v-alert v-show="actionAlert" border="left" color="error" dark text> Invalid credentials </v-alert>
                  </v-fade-transition>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      hide-details="auto"
                      class="mb-6"
                      :error-messages="errors.email"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      :error-messages="errors.password"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-btn block color="primary" type="submit" class="mt-6"> Login </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import axios from '@axios'
import { useRouter } from '@core/utils'
import store from '@/store'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const errors = ref([])
    const actionAlert = ref(false)

    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')

    const { router } = useRouter()

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return
      axios
        .post('/login', { email: email.value, password: password.value })
        .then(response => {
          const accessToken = response.data

          localStorage.setItem('accessToken', accessToken)

          return response
        })
        .then(() => {
          axios.get('/current-user').then(response => {
            const user = response.data

            localStorage.setItem('userData', JSON.stringify(user))
            store.commit('auth/setUserCan', user.roles)
            router.push('/')
          })
        })
        .catch(error => {
          const code = error.response.status
          if (code === 422) {
            errors.value = error.response.data.errors
          } else if (code === 401) {
            actionAlert.value = true
          }
        })
    }

    return {
      // variables
      errors,
      actionAlert,
      isPasswordVisible,
      email,
      password,

      handleFormSubmit,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
